// Auto-generated file created by svgr-core
// Run npm run svg:generate to update
import React, { SVGProps } from 'react'
const IconArrowLeft = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
      <path
        fill="#d6d7db"
        fillRule="evenodd"
        d="M11.355 5.284a.75.75 0 0 1-.002 1.042l-4.077 4.207H21.25a.75.75 0 0 1 .75.75v1.447a.75.75 0 0 1-.75.75H7.277l4.076 4.207a.75.75 0 0 1 .002 1.042l-1.027 1.066a.75.75 0 0 1-1.08.001l-7.03-7.268a.75.75 0 0 1 0-1.043l7.03-7.269a.75.75 0 0 1 1.08.001z"
        clipRule="evenodd"
      />
    </svg>
  )
}
export { IconArrowLeft }
