import React from 'react'

import { HeaderContainer, HeaderTitle } from '../../styled'

export const StatelessHeader = ({ title }: { title: string }) => {
  return (
    <HeaderContainer>
      <HeaderTitle variant="heading3">{title}</HeaderTitle>
    </HeaderContainer>
  )
}
