import { useRouter } from 'next/router'
import React from 'react'

import { IconButton, IconButtonProps } from 'ui-elements/IconButton'
import { IconArrowLeft } from 'ui-elements/Icons/IconArrowLeft'

import { PAGE_TRANSITION_DURATION_MS } from 'shared/constants/animations'
import { eventEmitter } from 'shared/utils/eventEmitter'

/**
 * BackButton component props
 */
export interface BackButtonProps extends IconButtonProps {
  iconColor?: string
}

/**
 * Renders back button that allows users to navigate back
 */
const BackButton = ({ iconColor, ...props }: BackButtonProps) => {
  const router = useRouter()

  const handleBack = () => {
    eventEmitter.emit('routeChangeIntent')
    setTimeout(() => {
      router.back()
    }, PAGE_TRANSITION_DURATION_MS)
  }

  return (
    <IconButton size="large" {...props} onClick={handleBack}>
      <IconArrowLeft fill={iconColor} />
    </IconButton>
  )
}

export { BackButton }
