import { GetStaticProps } from 'next'
import styled from 'styled-components'

import { StatelessHeader } from 'shared/components/Onboarding/Header/components/StatelessHeader'

import { APP_NAME } from 'shared/constants/app'

const Main = styled.main`
  flex: 1 0 auto;
`

const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      name: 'home',
    },
  }
}

const Page = () => {
  return (
    <>
      <StatelessHeader title={APP_NAME} />
      <Main></Main>
    </>
  )
}

export { Page, getStaticProps }
