import React from 'react'
import styled from 'styled-components'

import { BaseButton } from 'ui-elements/BaseButton'

/**
 * IconButton component props
 */
export interface IconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  /**
   * IconButton disabled state
   * If true, the button will be disabled
   * @default 'false'
   */
  disabled?: boolean
  /**
   * IconButton size
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large' | 'xlarge'
  /**
   * IconButton type
   * @default 'button'
   */
  type?: 'button' | 'submit' | 'reset'
}

interface StyledIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type: IconButtonProps['type']
  disabled: Required<IconButtonProps>['disabled']
  $size: Required<IconButtonProps>['size']
}

const getPadding = ({ $size }: StyledIconButtonProps) => {
  if ($size === 'medium') {
    return 4
  }

  if ($size === 'large') {
    return 8
  }

  if ($size === 'xlarge') {
    return 12
  }

  return 0
}

const StyledButton = styled(BaseButton)<StyledIconButtonProps>`
  padding: ${getPadding}px;
  color: ${({ theme }) => theme.color('action-primary-default')};

  &:hover {
    color: ${({ theme }) => theme.color('action-primary-hover')};
  }

  &:active {
    color: ${({ theme }) => theme.color('action-primary-active')};
  }

  &:disabled {
    color: ${({ theme }) => theme.color('action-primary-disabled')};
  }
`

/**
 * Renders IconButton component that allows users to take actions, and make choices, with a single tap.
 */
const IconButton = ({
  disabled = false,
  size = 'medium',
  type = 'button',
  ...rest
}: IconButtonProps): JSX.Element => {
  return <StyledButton type={type} disabled={disabled} $size={size} {...rest} />
}

export { IconButton }
